import { gql } from '@urql/core';

export const UPLOAD_DOCUMENT = gql`
  mutation ($input: DocumentUploadInput!) {
    uploadPersonDocument(input: $input) {
      id
      key
      name
      maxSize
      records {
        asset {
          absolute_url
          created_at
          extension
          filename
          id
          updated_at
          filesize {
            raw
            human
            __typename
          }
          __typename
        }
        created_at
        id
        name
        updated_at
        downloadable_for_customer
        __typename
      }
      template {
        absolute_url
        __typename
      }
      type
      visible_for_customer
      deletable_for_customer
      __typename
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation ($input: DocumentDeleteInput!) {
    deletePersonDocument(input: $input) {
      id
      key
      name
      records {
        asset {
          absolute_url
          created_at
          extension
          filename
          id
          updated_at
          filesize {
            raw
            human
            __typename
          }
          __typename
        }
        created_at
        id
        name
        updated_at
        downloadable_for_customer
        __typename
      }
      template {
        absolute_url
        __typename
      }
      type
      visible_for_customer
      deletable_for_customer
      __typename
    }
  }
`;

export const UPLOAD_ONBOARDING_DOCUMENT = gql`
  mutation ($input: OnboardingDocumentUploadInput!) {
    uploadOnboardingDocument(input: $input) {
      id
      key
      name
      records {
        asset {
          absolute_url
          created_at
          extension
          filename
          id
          updated_at
          filesize {
            raw
            human
            __typename
          }
          __typename
        }
        created_at
        id
        name
        updated_at
        downloadable_for_customer
        __typename
      }
      template {
        absolute_url
        __typename
      }
      type
      visible_for_customer
      deletable_for_customer
      __typename
    }
  }
`;
